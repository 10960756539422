import React from "react";
import { graphql, Link } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import { CareerPostDataQuery, CaseDataQuery } from "../../../graphqlTypes";
import IBMFontUrl from "../../fonts/ibm-plex-serif-v10-latin-regular.woff2";
import Breadcrumb from "@components/Breadcrumb";
import BannerWrapper from "@ui/BannerWrapper";
import Button from "@ui/Button";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody, mdxExcerpt } from "@utils/mdxUtils";
import { CustomMarkdownComponents } from "@utils/markdownRendering";
import { JsonLd } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
  query CaseData($slug: String!) {
    allCases: allStrapiCustomerCases {
      nodes {
        slug
        companyName
      }
    }
    allStrapiCustomerCases(filter: { slug: { eq: $slug } }) {
      nodes {
        childStrapiCustomerCasesContent {
          childMdx {
            body
          }
        }
        pageTitle
        companyName
        title
        created_at
        excerpt1
        excerpt2
        companyLogo {
          id
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
          alternativeText
        }
        companyPersonName
        companyPersonTitle
        companyPersonAvatar {
          id
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
          alternativeText
        }
      }
    }
  }
`;

const CareerPost = ({ data }: { data: DeepNonNullable<CaseDataQuery> }) => {
  const { allStrapiCustomerCases, allCases } = data;
  const { nodes } = allStrapiCustomerCases;
  const {
    pageTitle,
    title,
    companyName,
    childStrapiCustomerCasesContent: content,
    companyPersonName,
    companyPersonTitle,
    companyPersonAvatar,
    excerpt1,
  } = nodes[0];
  const { nodes: cases } = allCases;

  return (
    <Layout>
      <Seo title={pageTitle}>
        <link
          rel="preload"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
          href={IBMFontUrl}
        />
      </Seo>

      {/*<JsonLd*/}
      {/*  defer={false}*/}
      {/*  json={{*/}
      {/*    "@context": "https://schema.org",*/}
      {/*    "@type": "JobPosting",*/}
      {/*    description: mdxExcerpt(description),*/}
      {/*    employmentType: type == "fulltime" ? "Full-time" : "Part-time",*/}
      {/*    title: jobTitle,*/}
      {/*    datePosted: createdAt,*/}
      {/*    jobLocation: {*/}
      {/*      address: location,*/}
      {/*    },*/}
      {/*    hiringOrganization: {*/}
      {/*      name: "AccuRanker ApS",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}

      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto px-4 xl:px-0 mt-4 mb-4">
        <Breadcrumb
          parents={[{ title: "Cases", url: "/cases/" }]}
          title={companyName}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <p className="text-4xl text-white font-semibold mt-16 mb-6">
              {companyName}
            </p>
            <p className="text-gray-300 font-light">{title}</p>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="max-w-fit bg-accuRankerCream w-full py-12 mx-5 md:m-auto px-4 xl:px-0">
          <div className="max-w-7xl mx-auto mb-16 shadow-lg border-2 rounded-xl">
            <figure className="md:flex bg-gray-100 rounded-xl p-8 md:p-0 mb-0">
              {!companyPersonAvatar.localFile.childImageSharp &&
              companyPersonAvatar.localFile.extension === "svg" ? (
                <img
                  src={companyPersonAvatar.localFile.publicURL}
                  alt={companyPersonAvatar.alternativeText}
                  className="w-32 h-32 md:w-52 md:h-auto md:rounded-none md:rounded-l-xl rounded-full mx-auto"
                />
              ) : (
                <GatsbyImage
                  key={companyPersonAvatar.id}
                  image={
                    companyPersonAvatar.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={companyPersonAvatar.alternativeText}
                  className="w-32 h-32 md:w-96 md:h-auto md:rounded-none md:rounded-l-xl rounded-full mx-auto"
                />
              )}

              <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
                <blockquote className="md:ml-0">
                  <p className="text-lg font-semibold">“{excerpt1}”</p>
                </blockquote>
                <figcaption className="font-medium">
                  <div>{companyPersonName}</div>
                  <div className="text-gray-500">
                    {companyPersonTitle}, {companyName}
                  </div>
                </figcaption>
              </div>
            </figure>
          </div>
          <div className="max-w-7xl relative mx-auto mt-4 mb-4 grid grid-cols-8 gap-10 px-4 lg:px-0">
            <div className="col-start-1 col-end-9 lg:col-end-7">
              <MDXRenderer components={CustomMarkdownComponents}>
                {mdxBody(content)}
              </MDXRenderer>
            </div>
            <div className="hidden lg:block col-span-2">
              <div className="sticky top-36 flex flex-col gap-y-2 items-start bg-white p-8 pt-0 rounded-xl border-2 max-h-96 overflow-y-auto">
                <p className="font-medium text-lg sticky top-0 bg-white pt-8 pb-4 mb-0">
                  Read other cases
                </p>
                <ul className="ml-0 list-inside list-disc mt-0">
                  {cases.map(caseEntry => {
                    const { companyName, slug } = caseEntry;
                    return (
                      <li className="text-accuRankerOrange-500">
                        <span className="text-black">
                          <Link to={`/cases/${slug}/`}>{companyName}</Link>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default CareerPost;
